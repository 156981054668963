// --file.js--
class DkScanHowHelpers{
    constructor(){
    }

    static navigateTo(hash){
        console.log("Navigate to1234444 : " + hash);
        var currenthash = window.location.hash;
        var newhash = ( hash != "" ? '#' + hash : '' )
        if( currenthash!= newhash) {
            window.location.hash = newhash;
        }
    }

    static getSecondaryApp(){
        if( !this.secondaryApp ){
            this.secondaryApp = firebase.initializeApp(config.firebaseConfig, "Secondary");
        }
        return this.secondaryApp
    }

    /*Below is properly not relevant, as most of this can be checked with PropTypes Typechecking. Though I've experienced that a required string, slipped through typechecikng if I used a boolean*/
    static checkForExpectedProps( expectedProps, props, caller ){
        /*const expectedProps = ["fieldName", "placeHolder"]*/
        let missingProps =[];
        //Confirm expected props
        if( expectedProps != null ){
            expectedProps.forEach( function(expectedProp){
                if( !props[expectedProp] ){
                    missingProps.push(expectedProp);
                }
            }, caller);
            if( missingProps ) {
                console.log("Missing props : [" + missingProps + "] in '" + ((caller.constructor != null && caller.constructor.name != null) ? caller.constructor.name : caller) + "'");
            }
        }
        return missingProps;
    }

    static createUserFailed(error, callback) {
        var errorCode = error.code;
        var errorMessage = error.message;

        let errMsg = "";
        switch(errorCode) {
            case 'auth/weak-password':
                errMsg = DkScanHowLocalisation.t("web_general_errormsg_unsafepassword");
                break;
            case "auth/email-already-in-use":
                errMsg = DkScanHowLocalisation.t("web_general_errormsg_emailalreadyinsue");
                break;
            case "auth/invalid-email":
                errMsg = DkScanHowLocalisation.t("web_general_errormsg_invalidemail");
                break;
            default:
                errMsg = DkScanHowLocalisation.t("web_general_errormsg_anerroroccurred");
                console.log(JSON.stringify(error, null, '  '))
        }
        console.log(errMsg);
        console.log(error);
        callback(errMsg)
        /*_this.setState({errorMsg:errMsg});*/
    }

    static objectToArray(json) {
        if( json ) {
            return Object.keys(json).map((key) => {
                return Object.assign(json[key], {key});
            })
        }else{
            /*console.log("Called objectToArray without data");*/
            return [];
        }
    };

    static objectToArraySorted(json, sortKey) {
        var array = this.objectToArray(json);
        if( array.length > 0 ){
            array= this.sortArrayByProperty(array, sortKey);
        }
        return array;
    };

    static objectToArrayWithKey(json, keyName) {
        if( json ) {
            return Object.keys(json).map((key) => {
                return Object.assign(json[key], {keyName});
            })
        }else{
            console.log("Called objectToArray without data");
            return null;
        }
    };

    /*
    Se denne hvis behov for index http://stackoverflow.com/questions/40561611/displaying-data-from-firebase-in-react-without-arrays
    static objectToArray2(json){
        Object.keys(json).map((key, index) => {
        let instrument = instruments[key];
        // You can now use instrument.name and instrument.image
        return  <Instrument name={instrument.name} image={instrument.image}/>
    });*/

    static keysSorted(array) {
        var keysSorted = Object.keys(array).sort(function (a, b) {
            return array[a] - array[b]
        })
        return keysSorted;
    }

    static keysSortedByValue(array, key) {
        var keysSorted = Object.keys(array).sort(function (a, b) {
            return array[a] - array[b]
        })
        return keysSorted;
    }

    static sortArrayByProperty(array, propertyKey){
        return array.sort(function(a, b){
            return a[propertyKey] - b[propertyKey];
        });
    }

    static sortArrayByProperties(array, primaryKey, secondaryKey){
        return array.sort(function(a, b){
            if( a[primaryKey] - b[primaryKey] == 0){
                return a[secondaryKey] - b[secondaryKey];
            }else {
                return a[primaryKey] - b[primaryKey];
            }
        });
    }

    //Returns undefined if no key with this value is present
    static getFirstItemInArrayByValueOfChildKey(array, key, value){
        return array.find(function(child){
            if( child[key]=== value ) {
                return child;
            }
        });
    }

    static selectedGroupsAsString(selectedGroups, accountGroups){
        var selectedGroupsTemp = [];
        var selectedGroupsArray = DkScanHowHelpers.objectToArray(selectedGroups);

        for(var index = 0; index < selectedGroupsArray.length ; index++){
            //Necessary when I had false values in the array
            /*if( groups[index].valueOf() )*/
            {
                var item = DkScanHowHelpers.getFirstItemInArrayByValueOfChildKey(accountGroups, "groupKey", selectedGroupsArray[index].key);
                if( item != undefined && item["groupName"] && selectedGroupsArray[index].valueOf()) {
                    selectedGroupsTemp.push(item["groupName"]);
                }
            }
        }
        return selectedGroupsTemp.toString();
    }

    static getAccountGroupsAsArray(accountKey, resultCallback){
        firebase.database().ref('accountGroups/' + accountKey).on('value', (snapshot)=> {
        var groups = DkScanHowHelpers.objectToArray(snapshot.val());
        resultCallback(groups);
     }, function (error) {
        // An error happened.
        DkScanHowHelpers.logError(error);
        });
     }

    static uniqueElementId(parentId, elementId){
        return parentId+"__"+elementId;
    }

    static logError(error){
        //debugger;
        const errorCode = error.code;
        const errorMessage = error.message;
        switch(errorCode){
            case 'auth/wrong-password' :
                showSnackbar(DkScanHowLocalisation.t("web_general_errormsg_wrongpassword"));
                break;
            case "auth/user-not-found" :
                showSnackbar(DkScanHowLocalisation.t("web_general_errormsg_usernotfound"));
                break;
            case "auth/invalid-email":
                showSnackbar(DkScanHowLocalisation.t("web_general_errormsg_invalidemail"));
                break;
            default:
                console.log("Error : " +error.code + " / " + error.message);
                //showSnackbar(errorMessage);
        }
    }

    static displayModal( show ) {
        var modal = document.getElementById('dkScanHowModal');
        modal.style.display = show ? "block" : "none";
    }

    //Returns value at descendant path, and undefined if path is not set.
    static getJsonDescendant(json, pathString, valueOnNull, valueOnUndefined){
        var pathArray = pathString.split(".");

        var childNodeAtLvl = json;
        for( var lvl = 0 ; lvl < pathArray.length ; lvl++ ){
            var pathKeyAtLvl = pathArray[ lvl ]
            childNodeAtLvl = childNodeAtLvl[pathKeyAtLvl];
            if( childNodeAtLvl == null ){
                return valueOnNull;
            }
            if( childNodeAtLvl == undefined ){
                return valueOnUndefined;
            }
        }
        return childNodeAtLvl;
    }

    //2014-01-14 09:04:39
    static dateForExport(date){
        return date.toLocaleString();
    }

    static switchPrintMode(){
        var elem = window.document.getElementById("root2");
        if( elem.classList.contains( "printMode" ) ){
            elem.classList.remove("printMode");
        }else{
            elem.classList.add("printMode");
        }
    }

    static purchaseJson(accountTypeId, uid, debitorNo, accountKey, courseId, storageId, supportId, workflowsId, customPrice, enterpriseLimits){

        var purchase = {
            "package": accountTypeId,
            "packageTitle": DkScanHowLocalisation.accountTypeTitle(accountTypeId),
            "uid": uid,
            "debitorNo": debitorNo,
            "accountKey": accountKey,
            "createdTime": firebase.database.ServerValue.TIMESTAMP,
            "courses": null,
            "storage": null,
            "support": null,
            "workflows": null,
            "customPrice": null,
            "enterpriseLimits": null
        };

        purchase["courses"]     = courseId      ? { "productId":courseId,   "title":DkScanHowLocalisation.productTitle(courseId)} : null;
        purchase["storage"]     = storageId     ? { "productId":storageId,  "title":DkScanHowLocalisation.productTitle(storageId)} : null;
        purchase["support"]     = supportId     ? { "productId":supportId,  "title":DkScanHowLocalisation.productTitle(supportId)} : null;
        purchase["workflows"]   = workflowsId   ? { "productId":workflowsId,"title":DkScanHowLocalisation.productTitle(workflowsId)} : null;

        purchase["customPrice"] = customPrice ? customPrice : null;
        purchase["enterpriseLimits"] = enterpriseLimits ? enterpriseLimits : null;

        return purchase
    }
}



function DkScanHowDbRefs() {}
DkScanHowDbRefs.Accounts = "accounts";
DkScanHowDbRefs.Public = "public";
DkScanHowDbRefs.Stats_Accounts = "stats/accounts";
DkScanHowDbRefs.SystemInformation ="systemInformation";
DkScanHowDbRefs.PurchaseHistory="purchaseHistory";
DkScanHowDbRefs.AccountType="accountType";
DkScanHowDbRefs.NoOfUsers="noOfUsers";

function DkScanHowConstants() {}
DkScanHowConstants.DemoAccountId = "demoAccount";

function DkScanHowUserRoles() {}
DkScanHowUserRoles.Viewer = "viewer";
DkScanHowUserRoles.Creator = "creator";
DkScanHowUserRoles.DemoUser = "demoUser";
